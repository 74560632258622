import React from "react";
import { Typography } from "@material-ui/core";
import NewsItem from "./NewsItem";
import { parseISO } from "date-fns";

export default ({ classes }) => {
  return [
    <NewsItem first time={parseISO("2025-02-14T20:23:00")} classes={classes}>
      <Typography gutterBottom>Epoch 539 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>107%</b>. This epoch represents an annualised return of{" "}
            <b>2.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>69%</b>. Exactly as predicted. This
            epoch’s rewards will be on the <b>18th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>123%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>0.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-02-12T19:37:00")} classes={classes}>
      <Typography gutterBottom>Epoch 538 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>84%</b>. This epoch represents an annualised return of{" "}
            <b>2.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>107%</b>. Exactly as predicted. This
            epoch’s rewards will be on the <b>13th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>69%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as predicted. This
            epoch’s rewards will be on the <b>13th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-02-07T07:12:00")} classes={classes}>
      <Typography gutterBottom>Epoch 537 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>108%</b>. This epoch represents an annualised return of{" "}
            <b>2.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>84%</b>. Slightly less than predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>8th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>107%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>500%</b>. This epoch represents an annualised return of{" "}
            <b>7.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-02-02T11:43:00")} classes={classes}>
      <Typography gutterBottom>Epoch 536 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>69%</b>. This epoch represents an annualised return of{" "}
            <b>1.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>108%</b>. Exactly as predicted. This
            epoch’s rewards will be on the <b>3rd February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>92%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>0.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>500%</b>. Exactly as predicted. This
            epoch’s rewards will be on the <b>29th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-01-25T19:05:00")} classes={classes}>
      <Typography gutterBottom>Epoch 535 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>121%</b>. This epoch represents an annualised return of{" "}
            <b>3.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>69%</b>. Exactly as predicted. This
            epoch’s rewards will be on the <b>29th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>108%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as predicted. This
            epoch’s rewards will be on the <b>29th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>500%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-01-21T21:00:00")} classes={classes}>
      <Typography gutterBottom>Epoch 534 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>142%</b>. This epoch represents an annualised return of{" "}
            <b>3.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>121%</b>. Less than
            predicted as we lost 1 slot battles, which was unavoidable. This
            epoch’s rewards will be on the <b>24th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>69%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-01-15T21:57:00")} classes={classes}>
      <Typography gutterBottom>Epoch 533 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>75%</b>. This epoch represents an annualised return of{" "}
            <b>1.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>142%</b>. Slightly less than
            predicted as we lost 1 slot battle, which was unavoidable. This
            epoch’s rewards will be on the <b>19th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>136%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-01-11T18:10:00")} classes={classes}>
      <Typography gutterBottom>Epoch 532 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>120%</b>. This epoch represents an annualised return of{" "}
            <b>3.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>75%</b>. Slightly less than
            predicted as we lost 1 slot battle, which was unavoidable. This
            epoch’s rewards will be on the <b>14th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>150%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>500%</b>. This epoch represents an annualised return of{" "}
            <b>7.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-01-06T21:34:00")} classes={classes}>
      <Typography gutterBottom>Epoch 531 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>59%</b>. This epoch represents an annualised return of{" "}
            <b>1.4%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>120%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>9th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>82%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>0.6%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>500%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>9th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-01-03T10:26:00")} classes={classes}>
      <Typography gutterBottom>Epoch 530 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>142%</b>. This epoch represents an annualised return of{" "}
            <b>3.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>59%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>4th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>120%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>4th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>500%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-12-28T10:51:00")} classes={classes}>
      <Typography gutterBottom>Epoch 529 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Sunday were from the previous epoch. Our luck was{" "}
            <b>74%</b>. This epoch represents an annualised return of{" "}
            <b>1.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>142%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>30th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>59%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-12-21T17:23:00")} classes={classes}>
      <Typography gutterBottom>Epoch 528 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Sunday were from the previous epoch. Our luck was{" "}
            <b>82%</b>. This epoch represents an annualised return of{" "}
            <b>2.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>74%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>25th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>142%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-12-17T21:25:00")} classes={classes}>
      <Typography gutterBottom>Epoch 527 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Sunday were from the previous epoch. Our luck was{" "}
            <b>104%</b>. This epoch represents an annualised return of{" "}
            <b>2.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>82%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>20th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>74%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-12-13T16:20:00")} classes={classes}>
      <Typography gutterBottom>Epoch 526 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>111%</b>. This epoch represents an annualised return of{" "}
            <b>2.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>104%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>15th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>82%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-12-07T20:44:00")} classes={classes}>
      <Typography gutterBottom>Epoch 525 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>74%</b>. This epoch represents an annualised return of{" "}
            <b>1.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>111%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>10th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>104%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>244%</b>. This epoch represents an annualised return of{" "}
            <b>0.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-12-01T20:14:00")} classes={classes}>
      <Typography gutterBottom>Epoch 524 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>97%</b>. This epoch represents an annualised return of{" "}
            <b>2.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>74%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>5th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>111%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>244%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>5th December</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-27T20:20:00")} classes={classes}>
      <Typography gutterBottom>Epoch 523 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>103%</b>. This epoch represents an annualised return of{" "}
            <b>2.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>97%</b>. Slightly less than
            predicted as we lost 1 height battle, this was unavoidable. This
            epoch’s rewards will be on the <b>30th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>74%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>500%</b>. This epoch represents an annualised return of <b>8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>244%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-21T20:20:00")} classes={classes}>
      <Typography gutterBottom>Epoch 522 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out yesterday were from the previous epoch. Our luck was{" "}
            <b>96%</b>. This epoch represents an annualised return of{" "}
            <b>2.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>103%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>25th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>104%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>500%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>25th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-16T19:40:00")} classes={classes}>
      <Typography gutterBottom>Epoch 521 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>118%</b>. This epoch represents an annualised return of{" "}
            <b>3.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>96%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>20th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>103%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>500%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2024-11-12T21:21:00")} classes={classes}>
      <Typography gutterBottom>Epoch 520 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>66%</b>. This epoch represents an annualised return of{" "}
            <b>1.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>118%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>15th November</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>96%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
  ];
};
